import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from '@services/auth-guard.service';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [    
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard] 
      },
      {
        path:"users",
        loadChildren: () => import('./component/users/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard]         
      },
      {
        path:"commessions",
        loadChildren: () => import('./component/commessionPage/commession.module').then(m => m.CommessionModule),
        canActivate: [AuthGuard]         
      }, 
      {
        path:"change-password",
        loadChildren: () => import('./component/changePasswordPage/change-password.module').then(m => m.ChangePasswordModule),
        canActivate: [AuthGuard]         
      }      
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./component/login-page/login.module').then(m => m.LoginModule),    
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./component/sign-up-page/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  },
  
];
