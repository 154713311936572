<div class="row">
    <div class="col-sm-12 col-md-4">
       <div *ngIf="rowsFound>0">Showing {{(pageIndex-1)*pageLength+1}} to {{currentPageEndRow}} of {{rowsFound}} entries</div>
    </div>
    <div class="col-sm-12 col-md-8 d-flex justify-content-end">
      
      <nav>
         <ul class="pagination">
           <li class="page-item" (click)="previoud()" [ngClass]="hasPrevious?'previous': 'disabled'">
             <a class="page-link">Previous</a>
           </li>
           <li class="page-item" *ngFor="let item of totalPage" [ngClass]="item == pageIndex ? 'active': 'active__text'" (click)="goPage(item)">
            <a class="page-link">{{item}}</a></li>
           
           <li class="page-item" (click)="next()" [ngClass]="hasNext?'next': 'disabled'">
             <a class="page-link">Next</a>
           </li>
         </ul>
       </nav>
      
    </div>
 </div>