import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';// A service to check if user is authenticated
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  isAuthenticated: boolean=false;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('token');
    if (token != undefined || token != null) {
      this.isAuthenticated = true;    
    }
    else {
      this.router.navigate(['/login'], {replaceUrl: true});
    }
    return this.isAuthenticated;
  }
}
