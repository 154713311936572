<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-md-block d-lg-none">
    <a
      (click)="toggleSidebar.emit()"
      class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)">
      <i class="mdi mdi-menu font-24"></i>
    </a>
  </li>
  <!-- ============================================================== -->
  <!-- Search -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item search-box">
    <a
      (click)="showSearch = !showSearch"
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)">
      <i class="ti-search"></i>
    </a>
    <form
      class="app-search position-absolute"
      [ngClass]="{ 'show-search': showSearch }">
      <input
        type="text"
        class="form-control"
        placeholder="Search &amp; enter" />
      <a class="srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
  </li> -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">  
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
   <app-language-page></app-language-page>

  <li class="nav-item dropdown ml-2" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
    <div class="row">
      <div class="col-3">
        <i class="fa fa-user-circle" style="font-size: 30px;"></i>
      </div>
      <div class="col-9">
        <h3 style="font-size: 14px; margin-top: 10px;"> {{ profile?.name }}</h3>
        <h3 style="font-size: 12px;"> {{ profile?.phone_no }}</h3>       
      </div>
    </div>

    </a>
    <div class="dropdown-menu user-dd" ngbDropdownMenu>
      
      <!-- <a class="dropdown-item" href="javascript:void(0)">
        <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a
      >       -->
      <a class="dropdown-item" [routerLink]="['/change-password']" [replaceUrl]="false">
        <i class="ti-wallet m-r-5 m-l-5"></i> {{ 'change-password' | translate}}</a>      
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="logout()">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> {{ 'logout' | translate }}</a>     
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
