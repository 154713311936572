import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  
  {
    path: "/dashboard",
    title: "dashboard",
    icon: "mdi mdi-gauge",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/users",
    title: "users",
    icon: "mdi mdi-account-multiple",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/commessions",
    title: "commessions",
    icon: "mdi mdi-cash",
    class: "",
    extralink: false,
    submenu: [],
  },
];
