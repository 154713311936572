<div class="row mt-2">
    <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false">
        <button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-group" data-toggle="dropdown" 
        aria-haspopup="true" aria-expanded="true">
            <img src="assets/images/lang/{{selectedLang}}_flag.png" alt="" width="26" height="26">                                 
        </button>   
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <a class="dropdown-item" *ngFor="let item of languageList; let i = index;"
            [class.active]="item.lang == selectedLang" (click)="dropdown.isOpen = !dropdown.isOpen"
            (click)="changeLanguage(item.lang)" >
              <li role="menuitem">
                <img class="mr-1" src="assets/images/lang/{{item.lang}}_flag.png" alt="" width="30px" height="30px">&nbsp;{{ item.title }}
              </li>
            </a>           
          </ul>  
       
     </div>
</div>