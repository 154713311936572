import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouterModule } from '@angular/router';
import { ModalModule, BsModalService} from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PhonePickerComponent,
  LanguagePageComponent,
  PaginHeaderComponent,
  PaginFooterComponent,
  SortingComponent,
    } from './index';
import { ToastrModule } from 'ngx-toastr';


const com = [
    PhonePickerComponent,
    LanguagePageComponent,
    PaginHeaderComponent,
    PaginFooterComponent,
    SortingComponent,
       
];

const pipes = [
  
]
@NgModule({
  declarations: [
    com,
    pipes    
  ],
  imports: [
    FormsModule,
    CommonModule,    
    BsDropdownModule,   
    RouterModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule,
  
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  exports: [
    com,
    pipes,    
    ModalModule,
    FormsModule,
    TranslateModule,
  ],
  providers: [
    pipes,
    BsModalService, 
    HttpClient
   
  ]
})
export class SharedModule { }
