import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiTokenService } from '@services/api-token.service';
import { ShowError, ShowWarningError }  from '../../message';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>(); 
  public showSearch = false;
  profile: any;

  constructor( private router: Router,
    private apiTokenServer: ApiTokenService,
    private toastr: ToastrService,) {}
  ngOnInit(): void {
    this.getProfile();
  }
  async getProfile(){    
    await this.apiTokenServer.get(`agent/agent-profile`,true)
    .then(async response=> {
      if(response.data.statusCode == 200){    
        if(response.data.data != null){
          var result = JSON.parse(response.data.data); 
          this.profile = result;
        } 
       }
       else{          
       ShowError(response.data.statusCode,this.toastr,response.data.message);                  
        return false;              
       }    
    })
    .catch(error=> {     
      ShowError(error.status,this.toastr,error.message); 
    });
}
  logout(){
    localStorage.removeItem('token');
    this.router.navigate(['/login'], { replaceUrl: true });
  }
}
