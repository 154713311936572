<div class="phone__picker">  
  <label for="phoneNo">{{ 'phoneNumber' | translate}}</label>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon1">
        <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false">
          <button id="button-basic" dropdownToggle type="button" class="btn btn-group dropdown-toggle" data-toggle="dropdown" style="padding: 0px;">  
            <img src="{{imageUrl}}" alt="" width="20px" height="20px"> &nbsp;{{ prefix }} 
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <a class="dropdown-item" *ngFor="let registerCountryCodeobj of registerCountryCode; let i = index;"
              [class.active]="registerCountryCodeobj.prefix == prefix" (click)="dropdown.isOpen = !dropdown.isOpen"
              (click)="selectedRegisterCountryCode(registerCountryCodeobj.prefix)">
              <li role="menuitem">
                <img class="mr-1" src="{{registerCountryCodeobj.imageUrl}}" alt="" width="30px" height="30px">&nbsp;{{registerCountryCodeobj.prefix}}&nbsp;{{registerCountryCodeobj.countryName}}
              </li>
            </a>           
          </ul>
        </div>
      </span>
    </div> 
    <input type="tel" class="form-control" (keyup)="checkPhoneNumber()" (keyup.enter)="checkPhoneNumber()" (focus)="checkPhoneNumber()" 
    [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}"  placeholder="phone" aria-describedby="basic-addon1"/> 
  </div>
  <div *ngIf="phoneNumber.length == 0" class="text-danger">                         
    {{ 'requiredFiled' | translate :{value: ('phoneNumber' | translate)} }}
  </div> 
  <div *ngIf="phonePattern" class="text-danger">                         
    {{ 'phoneInvaild' | translate }}
  </div> 
</div>

