import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/map';

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.scss']
})
export class LanguagePageComponent implements OnInit {

  lang: any;
  supportLanguages = ['en','my','th','zh'];
  @Input() left: boolean= false;
  selectedLang: string=localStorage.getItem('lang') || 'en';  
  languageList:Array<any> =[{lang: "en", 'title': "English"},{lang: "my", title: "မြန်မာ"},
    {lang: "th", title: "ประเทศไทย"},{lang: "zh", title: "中文"}];

  constructor( public translateService: TranslateService) {  }

  async ngOnInit() {
   
  }

  changeLanguage(lang: string){   
    this.selectedLang =lang;   
    this.translateService.use(lang);
    localStorage.setItem('lang',lang);  
  } 

}
