import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import * as JsEncryptModule from 'jsencrypt';
import { publicKey } from '../config';
interface PromiseCallback {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
}
@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {
  private axiosInstance: AxiosInstance;
  private isRefreshing: boolean;  
  private failedRequests: Array<PromiseCallback>;
  private apiUrl= "https://demoapi.securekyat.com/api/";//"https://skapi.securekyat.com/api";//"https://skapi.securekyat.com/api";

  constructor(   
    private router: Router, 
    private toast: ToastrService, ) {
    this.axiosInstance = axios.create();
    this.isRefreshing = false;
    this.failedRequests = [];
    this.setupInterceptors();
  }

  generateAesKey() {
    return window.crypto.subtle.generateKey(
      {
        name: 'AES-CBC',
        length: 256
      },
      true,
      ['encrypt', 'decrypt']
      )
      .then(key => {
        return window.crypto.subtle.exportKey('raw', key);
      })
      .then(buffer => {       
        const keyArray = Array.from(new Uint16Array(buffer));
        const keyHex = keyArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        const key= keyHex.substring(0, 16).toLocaleUpperCase();
        return key;
      });
  }
  private RSAEncryptKey(key){    
   let rsaEnc= new JsEncryptModule.JSEncrypt();
   rsaEnc.setPublicKey(publicKey);
    let enc = rsaEnc.encrypt(key);
    return enc;    
  }
  private aesDecry(key, data){
    if(key==undefined||data==undefined ||key==null||data==null ||key==""||data==""){
      return null;
    }
  let _key = CryptoJS.enc.Utf8.parse(key); 
   return CryptoJS.AES.decrypt(
      data, _key, {
       keySize: 16,
       iv: _key,
       mode: CryptoJS.mode.CBC,
       padding: CryptoJS.pad.Pkcs7
     }).toString(CryptoJS.enc.Utf8);  
  }
  
  private aesEncrypt(key, data){
    if(key==undefined||data==undefined ||key==null||data==null ||key==""||data==""){
      return "";
    }
   let _key = CryptoJS.enc.Utf8.parse(key);     
   let enc= CryptoJS.AES.encrypt(
       data, _key, {
       keySize: 16,
       iv: _key,
       mode: CryptoJS.mode.CBC,//ECB,
       padding: CryptoJS.pad.Pkcs7
     });
  
     return enc.toString()
  }

  private setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const tokenKey = localStorage.getItem('tokenKey');
        const accessToken= localStorage.getItem("token");
        const asekey = CryptoJS.enc.Utf8.parse(tokenKey); 
        let token="";
        if(accessToken!=null){
          token = await this.aesDecry(tokenKey, accessToken);          
        } 
        if (token!=null && token!=undefined) {
          config.headers = {
            ...config.headers,
            Authorization: "Bearer "+token
          };
        }else{
         
          return Promise.reject(new Error('needlogin'));
        }
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if(error.message=="needlogin"){
         
          this.processFailedRequests(error);
          return Promise.reject(error);
        }
       
        const originalRequest = error.config;

        if(error.config.timeout ==0 && error.response?.status == 403 ){
          this.isRefreshing = false;
          console.log(JSON.stringify(error))
          localStorage.removeItem('token_en');
          
          this.router.navigate(['/user-block'], { state: {message: error.response.data}, replaceUrl: true });          
          this.processFailedRequests(error);
          return Promise.reject(error);
        } 
        // Retry the request if it failed due to an expired token
        // if (error.config.timeout ==0||error.response?.status === 401 && !originalRequest['retryAttempt']) {         
        //   if (this.isRefreshing) {
        //     // If token is already being refreshed, add the request to the failedRequests array
        //     return new Promise((resolve, reject) => {
        //       this.failedRequests.push({ resolve, reject });
        //     }).then(() => {
        //       return this.axiosInstance(originalRequest);
        //     });
        //   }

        //   originalRequest['retryAttempt'] = true;
        //   this.isRefreshing = true;
        //   return  await this.refreshAccessToken()
        //     .then((newToken) => {
        //       if (newToken) {
                
        //         originalRequest.headers = {
        //           ...originalRequest.headers,
        //           Authorization: `Bearer ${newToken}`
        //         };

        //         // Retry the original request and resolve the pending promises
        //         this.processFailedRequests(null, newToken);
        //         return this.axiosInstance(originalRequest);
        //       }
        //       // If refreshing the token failed, redirect to the login page or handle the error as needed
        //       // For example: window.location.href = '/login';
        //       this.processFailedRequests(error);
        //       return Promise.reject(error);
        //     })
        //     .catch(() => {
        //       // Refreshing the token failed, redirect to the login page or handle the error as needed
        //       // For example: window.location.href = '/login';
        //       this.processFailedRequests(error);
        //       return Promise.reject(error);
        //     })
        //     .finally(() => {
        //       this.isRefreshing = false;
        //       this.failedRequests = [];
        //     });
        // }
        return Promise.reject(error);
      }
    );
  }

  private processFailedRequests(error: AxiosError | null, newToken?: string) {
    this.failedRequests.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(newToken);
      }
    });
  }

  // private async refreshAccessToken(): Promise<string | null> {                   
  //         //end tokenkey    
  //         let rsaEnc =this.RSAEncryptKey(this.tokenKey); 
  //         const encKey= encodeURIComponent(rsaEnc);            
  //         let token = localStorage.getItem("token_en");          
  //         let formData = new FormData();
  //         formData.append('data', token);  
  //         let data = await axios.post(`${this.apiUrl}authenticate/RefreshToken?secretKey=${encKey}`,formData)
  //         .then(async response=> {   
  //          // console.log(JSON.stringify(response.data)) 
  //           let data= response.data;        
  //           if(data.statusCode == 200){  
  //             // console.log(JSON.stringify(data))               
  //                 let token = await this.aesDecry(this.tokenKey, response.data.data);                     
  //                 localStorage.setItem('token_en', response.data.data); 
  //                 return token;
  //            }             
  //            else{
  //             localStorage.removeItem('token');
  //             this.toast.error('', response.data.message,{
  //               timeOut: 6000,
  //               positionClass: 'toast-top-center',
  //             });
  //             this.router.navigate(['/login'],{replaceUrl: true});
  //             return null;              
  //            }    
  //         })          
  //         .catch(error=> {  
  //           return null;    
  //         });
  //         return data;
  // }

  async get(url: string,isEncry: boolean=false) {
    if(isEncry){
      let aesKey= await this. generateAesKey();
      let secretKey= await this.RSAEncryptKey(aesKey);
      if(url.includes('?')){
        url+="&secretKey="+encodeURIComponent(secretKey);
      }else{
        url+="?secretKey="+encodeURIComponent(secretKey);
      }
     
    return  this.axiosInstance.get(this.apiUrl+ url).then(respone=>{
        if(respone.status == 200){
          respone.data.data=this.aesDecry(aesKey,respone.data.data);
          return respone;
         }
      });

    }else{
      return this.axiosInstance.get(this.apiUrl+ url);
    }    
  }
  async getStr(url: string) {
    return this.axiosInstance.get(`${this.apiUrl}${url}`);
  }
  async post(url: string, data: any,isEncry:boolean=false, formData: boolean=true) {
    if(isEncry){
    let aesKey=await this. generateAesKey();
    data= await this.aesEncrypt(aesKey,data);
    let dataToPost =null;
    if(formData){
      dataToPost = new FormData();  
      dataToPost.append('data', data);
    }else{
      dataToPost=data;
    }   
    let secretKey= await this.RSAEncryptKey(aesKey);
    if(url.includes('?')){
      url+="&secretKey="+encodeURIComponent(secretKey);
    }else{
      url+="?secretKey="+encodeURIComponent(secretKey);
    }
    return this.axiosInstance.post(this.apiUrl+ url, dataToPost).then(async response=>{
     if(response.status == 200){  
      if(response.data.message == 'Token'){
        localStorage.setItem('tokenKey', aesKey);
        localStorage.setItem('token', response.data.data);
      }
      if(response.data.data != null && response.data.message != "Token"){
        response.data.data = await this.aesDecry(aesKey,response.data.data);          
      }
      return response;          
    
     }else{
      return response;
     }
     });

    }else{
      return this.axiosInstance.post(this.apiUrl+ url, data);
    }
   
  }
  // async login(url: string, data: any) {      
  //   data= await this.aesEncrypt(this.tokenKey,data);
  //   let formData = new FormData();  
  //   formData.append('data', data);
  //   let secretKey= await this.RSAEncryptKey(this.tokenKey);
  //   if(url.includes('?')){
  //     url+="&secretKey="+encodeURIComponent(secretKey);
  //   }else{
  //     url+="?secretKey="+encodeURIComponent(secretKey);
  //   }
  //   return  this.axiosInstance.post(this.apiUrl+ url, formData).then(async response=>{
  //    if(response.status==200 && response.data.data != null){  
  //     if(response.data.message !=  "Token"){
  //       response.data = await this.aesDecry(this.tokenKey,response.data.data); 
  //     }          
  //     return response;
  //    }else{
  //     return response;
  //    }
  //    });

    
   
  // }

  //getAllSection()
  async getAllSection(){
     let data = await JSON.parse(localStorage.getItem("twod4Section"));
     await this.getStr(`${this.apiUrl}twodsection/getTwodSectionList`)
      .then(response => {
        localStorage.setItem("twod4Section",JSON.stringify(response.data));      
      });
      return data;
  }


  async getActiveUsers(){
    let data = await JSON.parse(localStorage.getItem("activeusers"));
    await this.getStr(`${this.apiUrl}user/active-users`)
     .then(response => {
       localStorage.setItem("activeusers",JSON.stringify(response.data));      
     });
     return data;
 }


  


 
}
