import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiTokenService } from '@services/api-token.service';
import * as $ from "jquery";

@Component({
  selector: 'app-phone-picker',
  templateUrl: './phone-picker.component.html',
  styleUrls: ['./phone-picker.component.scss']
})
export class PhonePickerComponent implements OnInit {
 
  registerCountryCode: any;  
  imageUrl= "";
  prefix= "+66";
  regularExpression: string= '(^09\d{6,9}|^08\d{6,9}|^06\d{6,9}|^1[0-9]{10})$';//'^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$';
  phoneNumber= ""; 
  @Output() phoneValueChange = new EventEmitter<string>();  
  phonePattern: boolean=false;
 
  constructor( private toastr: ToastrService, 
     private apiTokenServer: ApiTokenService,)
    {
     
    }
  async ngOnInit(): Promise<void> {       
    this.registerCountryCode = await this.getCountryPhoneCodePrefix();
    if(this.registerCountryCode != null){
      this.changeRegisterCountryCode();   
    }        
  }

  changeRegisterCountryCode(){
    const prefixValue = this.registerCountryCode.find(e=> e.prefix == this.prefix);
      if(prefixValue != null){
        this.imageUrl= prefixValue.imageUrl;
        this.regularExpression = prefixValue.web_regularExpression;       
        this.phoneValueChange.emit(this.phoneNumber);        
        localStorage.setItem('phonePrefix', prefixValue.prefix);  
      } 
    }
  selectedRegisterCountryCode(code: string) {
      this.prefix = code;
      this.changeRegisterCountryCode();
  }

 async getCountryPhoneCodePrefix() {      
      let response ='';
      let localData =  await localStorage.getItem('CountryCode');
      if(localData != null && localData != undefined){
        const localObj = JSON.parse(localData);
        response = localObj;
      }       
      response = await this.apiTokenServer.getStr(`registerphoneprefix/getRegisterPhoneMobileList`)
     .then(response => {      
         localStorage.setItem('CountryCode',  JSON.stringify(response.data)); 
         return response.data;       
      })
     .catch(error => {
         return null;
     });
     return response;
      
    }
  checkPhoneNumber()
  {     
    
    if(this.phoneNumber != ""){      
      let phone='';
      if(this.phoneNumber.startsWith('0')) phone= this.prefix + this.phoneNumber.substring(1, this.phoneNumber.length);
      else phone = this.prefix + this.phoneNumber;     
      this.phoneValueChange.emit(phone);
      return true;
    }     
    let pattern = RegExp(this.regularExpression);
    if (this.phoneNumber != "" && !pattern.test(this.phoneNumber)) {    
      this.phonePattern = true;
      return false;
    }          
    return true;    
  }
  
}
